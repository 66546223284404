import { NgModule } from '@angular/core';
import { PlasmaComponent } from './plasma/plasma.component';

@NgModule({
  declarations: [
    PlasmaComponent
  ],
  exports: [
    PlasmaComponent
  ]
})
export class SharedModule { }
