import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plasma',
  templateUrl: './plasma.component.html',
  styleUrls: ['./plasma.component.scss']
})
export class PlasmaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const self = this;
    window.addEventListener('load', () => { this.onloadHandler(self); }, false);
    window.addEventListener('resize',  () => { this.resizeHandler(self); }, false);
  }

  RAD: any = Math.PI / 180.0;
  Sin: any = Math.sin;
  Cos: any = Math.cos;
  Sqrt: any = Math.sqrt;

  HEIGHT: any = undefined;
  WIDTH: any = undefined;
  g_canvas: any = undefined;
  g_framestart: any = undefined;

  CycleSpeed: any = 0;
  PlasmaDensity: any = 32;
  TimeFunction: any = 64;
  PlasmaFunction: any = 0;
  Jitter: any = 8;
  Alpha: any = 0.1;
  PaletteIndex: any = 2;

  // internal properties
  paletteoffset: any = 0;
  palettes: any = null;

  /**
   * Global window onload handler
   */
  onloadHandler(self: any) {
    // fullscreen the canvas element
    this.g_canvas = document.getElementById('plasma');
    this.WIDTH = this.g_canvas.width = window.innerWidth;
    this.HEIGHT = this.g_canvas.height = window.innerHeight;

    // create the Plasma object
    function rgb(r: any, g: any, b: any) {
      return "rgb(" + r.toString() + "," + g.toString() + "," + b.toString() + ")";
    }

    this.palettes = [];

    var palette = [];
    for (var i = 0; i < 256; i++) {
      palette.push(rgb(i, i, i));
    }
    this.palettes.push(palette);

    palette = [];
    for (var i = 0; i < 128; i++) {
      palette.push(rgb(i * 2, i * 2, i * 2));
    }
    for (var i = 0; i < 128; i++) {
      palette.push(rgb(255 - (i * 2), 255 - (i * 2), 255 - (i * 2)));
    }
    this.palettes.push(palette);

    palette = new Array(256);
    for (var i = 0; i < 64; i++) {
      palette[i] = rgb(i << 2, 255 - ((i << 2) + 1), 64);
      palette[i + 64] = rgb(255, (i << 2) + 1, 128);
      palette[i + 128] = rgb(255 - ((i << 2) + 1), 255 - ((i << 2) + 1), 192);
      palette[i + 192] = rgb(0, (i << 2) + 1, 255);
    }
    this.palettes.push(palette);

    palette = [];
    for (var i = 0, r, g, b; i < 256; i++) {
      r = ~~(128 + 128 * self.Sin(Math.PI * i / 32));
      g = ~~(128 + 128 * self.Sin(Math.PI * i / 64));
      b = ~~(128 + 128 * self.Sin(Math.PI * i / 128));
      palette.push(rgb(r, g, b));
    }
    this.palettes.push(palette);

    palette = [];
    for (var i = 0, r, g, b; i < 256; i++) {
      r = ~~(self.Sin(0.3 * i) * 64 + 190),
        g = ~~(self.Sin(0.3 * i + 2) * 64 + 190),
        b = ~~(self.Sin(0.3 * i + 4) * 64 + 190);
      palette.push(rgb(r, g, b));
    }
    this.palettes.push(palette);

    // init the animation loop
    this.g_framestart = Date.now();
    this.requestAnimFrame(() => {
      this.loop(self);
    });
  }

  /**
   * Global window resize handler
   */
  resizeHandler(self: any) {
    if (this.g_canvas) {
      this.WIDTH = this.g_canvas.width = window.innerWidth;
      this.HEIGHT = this.g_canvas.height = window.innerHeight;
    }
  }

  /**
   * Main render loop
   */
  loop(self: any) {
    var frameStart = Date.now();

    this.frame(self);

    this.requestAnimFrame(() => {
      this.loop(self);
    });
  }

  // animation frame rendering function
  frame(self: any) {
    // init context and img data buffer
    var w = self.WIDTH, h = self.HEIGHT,                      // canvas width and height
      pw = this.PlasmaDensity, ph = (pw * (h / w)),    // plasma source width and height
      ctx = self.g_canvas.getContext('2d'),
      palette = this.palettes[this.PaletteIndex],
      paletteoffset = this.paletteoffset += this.CycleSpeed,
      plasmafun = this.PlasmaFunction;
    // scale the plasma source to the canvas width/height
    var vpx = (w / pw), vpy = (h / ph);

    var dist = function dist(a: any, b: any, c: any, d: any) {
      return self.Sqrt((a - c) * (a - c) + (b - d) * (b - d));
    }

    var time = Date.now() / this.TimeFunction;

    const colour = function(x: any, y: any) {
      switch (plasmafun) {
        case 0:
          return ((self.Sin(dist(x + time, y, 128.0, 128.0) / 8.0)
            + self.Sin(dist(x - time, y, 64.0, 64.0) / 8.0)
            + self.Sin(dist(x, y + time / 7, 192.0, 64) / 7.0)
            + self.Sin(dist(x, y, 192.0, 100.0) / 8.0)) + 4) * 32;
          break;
        case 1:
        default:
          return (128 + (128 * self.Sin(x * 0.0625)) +
            128 + (128 * self.Sin(y * 0.03125)) +
            128 + (128 * self.Sin(dist(x + time, y - time, w, h) * 0.125)) +
            128 + (128 * self.Sin(self.Sqrt(x * x + y * y) * 0.125))) * 0.25;
          break;
      }
    }

    ctx.save();
    ctx.globalAlpha = this.Alpha;
    var jitter = this.Jitter ? (-this.Jitter + (Math.random() * this.Jitter * 2)) : 0;
    for (var y = 0, x; y < ph; y++) {
      for (x = 0; x < pw; x++) {
        // map plasma pixels to canvas pixels using the virtual pixel size
        ctx.fillStyle = palette[(~~colour(x, y) + paletteoffset) % 256];
        ctx.fillRect(x * vpx + jitter, y * vpy + jitter, vpx, vpy);
      }
    }
    ctx.restore();
  }

  requestAnimFrame = (function () {
    return window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      (window as any).mozRequestAnimationFrame ||
      (window as any).oRequestAnimationFrame ||
      (window as any).msRequestAnimationFrame ||
      function (callback: any, element: any) {
        window.setTimeout(callback, 1000 / 60);
      };
  })();
}
